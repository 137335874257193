.headerContainer {
  margin: 40px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerFlexWrap {
  display: flex;
  flex-wrap: wrap;
}

.companyHeaderFlex {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.emailContainer {
  font-size: 16px;
  margin-top: 5px;
}

.socialMediaBlock {
  margin-top: 5px;

  &.hasEmails {
    margin-top: 16px;
  }
}

.companyLogo {
  margin-left: auto;
  max-height: 120px;
  max-width: 120px;
  object-fit: contain;
  border-radius: 100%;
}
.companyNameFlex {
  display: flex;
  align-items: center;
  gap: 16px;
}
