.employeeGrid {
  display: flex;
  flex-wrap: wrap;
}

.employeesContainer { 
  flex: 1;
  background: white;
  border-radius: 12px;
  padding: 5px;
}
.employeeContainerSingle {
  background: white;
  border-radius: 12px;
  padding: 5px;
  width: 50%;
}

.button {
  width: 50%;
  padding: 10px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px;

  &:hover {
    background-color: #e0e0e0;
  }
}