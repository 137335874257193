.company-notes {
  width: 100%;
  max-width: 600px;
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  margin-bottom: 40px;

  h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }

  .note-input {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .ql-container {
      height: 100px;
    }

    .post-button {
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #9855e3;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #c285ff;
      }
    }
  }

  .notes-list {
    margin-top: 16px;

    .note {
      background-color: #ffffff;
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 10px;
      position: relative;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .note-date {
        font-size: 0.8em;
        color: #6c757d;
        margin-bottom: 10px;
      }

      .note-content {
        margin-bottom: 10px;
        color: #6c757d;
      }

      .edit-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.2em;
        cursor: pointer;
        color: #9855e3;
      }

      .save-button {
        display: inline-block;
        padding: 5px 10px;
        margin-top: 10px;
        background-color: #9855e3;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c285ff;
        }
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 20px 0;
  }

  .add-note-button {
    width: 100%;
    padding: 10px;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.ql-tooltip.ql-editing {
  left: 0 !important;
  top: 40px !important;
}
