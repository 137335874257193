.buttonContainer,
.buttonContainerSelected {
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 30px;
  background-color: rgb(237, 236, 236);
  color: rgb(72, 72, 73);
  font-weight: 700;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgb(179, 178, 179);
  }

  &:not(.buttonContainer) {
    background-color: rgb(76, 77, 76);
    color: rgb(254, 254, 255);
  }
}

.iconContainerEN,
.iconContainerJP {
  align-self: center;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 11px;
  &:not(.iconContainerEN) {
    margin-left: 10px;
  }
  .label {
    margin-left: 10px;
  }
  .caretDown,
  .caretSelected {
    height: 20px;
    margin-left: 40px;
    margin-bottom: 5px;
    background-color: transparent !important;
    &:not(.caretDown) {
      span {
        color: white !important;
      }
    }
  }
}

.dropdown {
  position: relative;
  top: 30px;
  background: white;
  box-shadow: 5px 5px 15px rgb(240, 241, 241);
  height: 80px;
  color: black;
  font-weight: normal;
  div {
    width: 150px;
    padding: 5px 10px;
    &:hover {
      background-color: rgb(237, 236, 236);
    }
  }
  .selectedLanguageOption {
    font-weight: 800;
  }
}
