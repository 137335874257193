.dashboardContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.searchBarContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // max-height: 500px;
  flex-direction: column;
}

.companyHistory {
  height: 100px;
  width: 100%;
  background: rgb(179, 125, 179);
}

.newContainer {
  display: flex;
}

.componentGrid {
  padding: 40px;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  > * {
    // flex-grow: 1;
  }
}

@media (max-width: 1024px) {
  .componentGrid {
    flex-wrap: wrap;
    align-items: stretch;

    > * {
      flex: 0 0 48%;
    }
  }
}

.teamActivity {
  padding: 40px;
}

.inputHeading {
  // font-family: 'Inter';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(90deg, #a460dc 0%, #d95180 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
}

// Add media queries for responsiveness
@media (max-width: 768px) {
  .bottomRow {
    flex-direction: column; // Stack components vertically
    align-items: stretch; // Ensure components take full width
    gap: 16px; // Adjust gap for better spacing
  }
}

@media (max-width: 480px) {
  .bottomRow {
    flex-direction: column; // Stack components vertically
    align-items: stretch; // Ensure components take full width
    gap: 12px; // Adjust gap for better spacing
  }
}

.newsContainer {
  display: flex;
  flex-direction: column;
}
