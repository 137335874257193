@import '~@blueprintjs/core/src/common/variables';
@import '../../../palette.module';

.detailsPagecontainer {
  display: flex;
  height: 100%;
}

.detailsPageMainContent {
  width: calc(100% - var(--right-sidebar-width));
  height: 100%;
  overflow: scroll;
}

.detailsPageContentWrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.employeesSection {
  display: flex;
  margin-bottom: 40px;
}

.companyPageNewsContainer {
  margin-bottom: 40px;
}

.noNewsText {
  font-size: large;
}

.employeeGrowth {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.employeeGrowthChartContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 50px;
}

.employeeGrowthChart {
  background: white;
  border-radius: 12px;
}
