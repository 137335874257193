.root {
  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 100%;
  align-items: center;
  gap: 10px;
  text-decoration: none !important;
  border-radius: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #E4E9EC;
  }

  &:active {
    background-color: #D5DADE;
  }
}
