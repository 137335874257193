// .basicsDataPointsTitle {
//   width: 42%;
// }

// .basicsDataPoints {
//   display: flex;
//   justify-content: space-between;
// }

// .basicsContainer {
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
// }

// .basicsTopRow {
//   display: flex;
//   gap: 16px;
//   position: relative;
// }

// .basicsBottomRow {
//   display: flex;
//   gap: 16px;
// }

// .basicsLeftColumn {
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   flex: 1;
// }

// .basicsRightColumn {
//   flex: 0 0 50%;
//   display: flex;
//   flex-direction: column;
// }

.dataPointsGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
}

.address {
  margin-top: 16px;
}