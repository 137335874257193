@import "palette.module";

$popover-shadows: border-shadow($pt-border-shadow-opacity), 0 2px 4px rgba($light-gray1, 0.58), 0 8px 24px rgba($light-gray1, 0.5);

.bp3-tooltip, .bp3-popover {
  // replace scale transition with fade transition, and from 300ms to 200ms
  @include react-transition("bp3-popover", (transform: scale(1) scale(1)), $duration: 200ms, $after: "> &");
}

.bp3-tooltip {
  // reduce size of popover arrow
  @include popover-sizing(
      $arrow-square-size: 14px,
      $arrow-offset: 4px,
      $arrow-target-offset: -4px
  );
  // replace popover colors and shadows
  @include popover-appearance($dark-gray3, $white, $popover-shadows, 0.58, $pt-border-shadow-opacity);

  & .bp3-popover-content {
    padding: 0 !important;

    & > * {
      max-height: 324px;
      max-width: 440px;
      overflow: auto;
      padding: 8px !important;
    }
  }
}

.bp3-popover-primary {
  // replace popover color
  @include popover-appearance($blue3, $white, $popover-shadows, $pt-drop-shadow-opacity, $pt-border-shadow-opacity);
}

.bp3-popover:not(.bp3-tooltip) {
  &.max-width {
    max-width: 470px;
  }

  &:not(.virtualized-list):not(.bp3-large-popover):not(.bp3-popover-boundless) .bp3-popover-content > * {
    max-height: 700px;
    max-width: 700px;
    overflow: auto;
  }

  &:not(.bp3-minimal) .bp3-popover-content > * {
    padding: 10px !important;
  }

  &.bp3-minimal .bp3-popover-content > * {
    padding: 5px !important;
  }

  .bp3-popover-content .bp3-menu {
    padding: 0;

    &:not(:first-child) {
      padding-top: 5px;
    }
  }

  &.bp3-large-popover {
    //max-width: 98%; // popover has padding and shadows that we don't want to clip on the right

    & > .bp3-popover-content {
      background-color: $background-blue;
    }

    & .bp3-popover-arrow-fill {
      fill: #eceef0;
    }
  }
}

.bp3-button-group {
  .bp3-button:not(:hover):not(:disabled):not(:active):not(.bp3-active) + .bp3-button:not(:hover):not(:disabled):not(:active):not(.bp3-active) {
    box-shadow: -10px 0px 0px -9px $light-gray1 !important;
  }
}

*:not(.bp3-minimal) > .bp3-button {
  background-image: none !important;
  box-shadow: none !important;

  // without this, ellipsis inside Buttons don't work
  .bp3-button-text {
    min-width: 0;
  }

  span.bp3-button-text > div > span {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }

  &:not([class*=bp3-intent-]):not(.bp3-minimal) {
    background-color: $light-gray3;

    &, & .bp3-icon {
      color: $dark-gray3;
    }

    &:not(:disabled) {
      &:hover {
        background-color: $light-gray1;
      }

      &:active, &.bp3-active, &:active .bp3-icon, &.bp3-active .bp3-icon {
        background-color: $dark-gray3 !important;
        color: $white;
      }
    }

    &:disabled {
      background-color: $light-gray5;
      color: $light-gray1;
    }
  }

  &.bp3-intent-primary {
    &:disabled {
      color: rgba($white, 0.5) !important;
    }

    &.bp3-minimal {
      &:not(:disabled) {
        color: $blue3 !important;

        &:hover {
          background-color: rgba($blue3, 0.08) !important;
        }

        &:active, &.bp3-active {
          background-color: rgba($blue3, 0.15) !important;
        }
      }

      &:disabled {
        color: rgba($blue3, 0.5) !important;
      }
    }
  }
}

.bp3-radio {
  & .bp3-control-indicator {
    background-image: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  & input:checked {
    & ~ .bp3-control-indicator {
      background-color: transparent !important;

      &::before {
        border-radius: 50% !important;
      }
    }

    &:disabled ~ .bp3-control-indicator::before {
      background-image: radial-gradient(rgba($white, 0.5), rgba($white, 0.5) 28%, rgba($blue3, 0.5) 32%) !important;
    }

    &:not(:disabled) {
      & ~ .bp3-control-indicator::before {
        background-image: radial-gradient($white, $white 28%, $blue3 32%) !important;
      }

      &:hover ~ .bp3-control-indicator::before {
        background-image: radial-gradient($white, $white 28%, $blue2 32%) !important;
      }

      &:active ~ .bp3-control-indicator::before, &.bp3-active ~ .bp3-control-indicator::before {
        background-image: radial-gradient($white, $white 28%, $blue1 32%) !important;
      }
    }
  }

  & input:not(:checked) {
    &:not(:disabled) {
      & ~ .bp3-control-indicator {
        background-color: $white !important;
        border: 1px solid $light-gray1 !important;
      }

      &:hover ~ .bp3-control-indicator {
        background-color: $light-gray3 !important;
      }

      &:active ~ .bp3-control-indicator, &.bp3-active ~ .bp3-control-indicator {
        background-color: $light-gray2 !important;
      }
    }
  }

  & input:disabled ~ .bp3-control-indicator {
    background-color: $light-gray5 !important;
  }

  &.bp3-disabled {
    color: $light-gray1 !important;
  }
}

.bp3-tag {
  background-color: rgba($dark-gray3, 0.15);
  color: $black;

  .bp3-tag-remove {
    opacity: 0.2;

    &:hover {
      opacity: 0.6;
    }

    &:active, &.bp3-active {
      opacity: 0.8;
    }
  }
}

.bp3-input {
  min-width: 180px;

  &::placeholder {
    color: $dark-gray4;
  }

  &:not(:disabled):not(:focus) {
    box-shadow: inset 0 0 0 1px $light-gray3, inset 0 1px 1px 0 $light-gray1 !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px $blue5, inset 0 0 0 1px $blue3, inset 0 1px 2px 0 $light-gray1 !important;
  }

  &:disabled {
    background-color: $light-gray5;
    color: $light-gray1;
  }
}

.bp3-input-group.bp3-intent-danger .bp3-input:not(:focus), .bp3-input:not(:focus).bp3-intent-danger {
  box-shadow: inset 0 0 0 1px $red3, inset 0 1px 2px 0 $light-gray1 !important;
}

.bp3-menu {
  min-width: auto;

  .bp3-menu-item {
    // blueprint bug: without this, the focus outline on MenuItems is clipped on the sides
    &:focus {
      outline-offset: -1px;
    }

    &.bp3-active.bp3-intent-primary {
      background-color: $blue3 !important;
      color: $white !important;
    }
  }
  
  &.bp3-disabled {
    color: $light-gray1 !important;
  }
}


.bp3-select-popover {
  & .bp3-input-group {
    & .bp3-icon-search {
      display: none;
    }

    & .bp3-input {
      padding-left: 10px !important;
    }
  }
}

.bp3-switch {
  margin-bottom: 0;

  & .bp3-control-indicator {
    margin-right: 0;
  }
}

.bp3-numeric-input-full-width, .bp3-numeric-input-full-width .bp3-input-group {
  width: 100%;
}

// progress bar
.bp3-progress-bar {
  background-color: rgba(92, 112, 128, 0.2) !important;
}

// we go against blueprint by forcing a white background in the IP wizard dialog
.bp3-dialog.ip-wizard {
  background: $white;
  padding-bottom: 0;
}

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: $white !important;
}

.bp3-spinner.bp3-intent-primary .bp3-spinner-track {
  stroke: rgba($white, 0.2) !important;
}

// blueprint bug: focus ring includes hidden text; we hide it by setting its font-size to 0
// see: https://github.com/palantir/blueprint/issues/5131
.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:first-child {
  transform: initial;
}

.bp3-control.bp3-switch .bp3-control-indicator-child:first-child,
.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:last-child {
  transform: scale(1, 0);
}

.bp3-form-group {
  margin: 0 !important;
}

.bp3-navbar {
	box-shadow: 0px -2px 4px 0px rgb(178 178 178 / 20%), 0px -2px 4px 0px rgb(178 178 178 / 20%) !important;
  // margin-left: -8px;
  padding-right: 24px;
  width: 100.5%;
}

.bp3-navbar-group {
  // margin-left: -7px;
}

.bp3-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}

.alert > .bp3-popover-content {
    padding: 8px !important;
    color: #ffffff;
    background-color: #484849;
}

.alert > .bp3-popover-arrow {
  display: none;
}

.bp3-toast-message {
  display: flex;
  justify-content: space-between;
}

.bp3-toast.bp3-intent-success, .bp3-toast.bp3-intent-danger {
  width: 2000px;
}
