@import '../../palette.module';

.percentageBar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.percentageBar > span {
  min-width: 38px;
  text-align: right;
}

.topBar {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  background: rgba(245, 248, 250, 1);
  padding: 10px 24px;
  height: fit-content;
}

.functionButtons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.topBar > p {
  margin: 0 auto 0 0;
  font-weight: bold;
  font-size: 90%;
}

.companyHighlightTitle {
  width: 100%;
  height: 30px;

  span {
    padding-left: 10px;
    font-weight: bold;
    line-height: 1;
  }
}

.newList {
  padding: 24px;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  gap: 16px;
  width: 519px + 24px * 2;
  background-color: $white;
}

.formGroup {
  label {
    font-weight: 600;
  }

  textarea {
    width: 100%;
  }
}

.selectGroup {
  p {
    font-weight: 600;
  }

  .selectButton {
    width: 100%;
  }
}

.ListFormSelect {
  & > span {
    width: 100%;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > label {
    margin-bottom: 0 !important;
  }
}
