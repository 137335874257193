.buttonSelected {
  padding: 8px;
  border-radius: 8px;
  background: #9855e3;
  color: white;
  cursor: pointer;
}

.button {
  padding: 8px;
  border-radius: 8px;
  background: white;
  color: black;
  cursor: pointer;
}
