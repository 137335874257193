.container {
  display: flex;
  gap: 16px;
  background: white;
  padding: 20px;
  border-radius: 12px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 360px;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.statsContainer {
  display: flex;
  gap: 16px;
  background-color: var(--background-blue);
  padding: 13px 16px;
}

.statsText {
  font-size: 16px;
  font-weight: 700;
}

.divider {
  margin-top: -38px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.statsRow {
  display: flex;
  gap: 10px;
}
