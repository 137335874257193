.descriptionCard {
  width: 100%;
  background-color: white; // Light gray background
  border-radius: 12px; // Rounded corners
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  padding: 24px; // Generous padding inside the card
  overflow: auto;
  flex-grow: 1;
  margin-bottom: 40px;

  // Adjust padding for smaller screens
  @media (max-width: 768px) {
    padding: 16px;
  }
}

.contentWrapper {
  font-family: 'Arial', sans-serif; // Sans-serif font (adjust to your design system if needed)
  font-size: 16px; // Body text size
  line-height: 1.6; // Improved readability
  color: #000000; // Black text
  background: white;
  // Style paragraphs
  p {
    margin-bottom: 16px; // Space between paragraphs
  }

  // Style bullet points
  ul {
    list-style: none; // Remove default bullets
    padding: 0;
    margin: 0;

    li {
      position: relative;
      padding-left: 24px; // Space for the checkmark
      margin-bottom: 10px; // Space between list items

      &:before {
        position: absolute;
        left: 0;
        top: 2px; // Align with text
      }
    }
  }
}
