.headerCard {
  width: 50%;
  margin-bottom: 40px;
}

.linkedinCard {
  background-color: #ffffff;
  border-radius: 10px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 10em;
  overflow: auto;
  display: flex;
  align-items: center;
}

/* Header section */
.linkedinHeaderContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.linkedinHeaderLogo {
  border-radius: 50%;
  border: 1px solid #dee3ec;
}

.linkedinInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.nameAndType {
  display: flex;
  align-items: center;
  gap: 10px;
}

.linkedinName {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.companyType {
  background-color: #f5f7fa;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 14px;
  color: #000000;
  border: 1px solid #999999;
}

.followersCount {
  font-size: 14px;
  color: #000000;
}

.linkedinUrl {
  font-size: 14px;
  color: #999999;
  text-decoration: none;
}

.linkedinUrl:hover {
  color: #0077b5;
  text-decoration: underline;
}

/* Employee section */
.employeeSectionHeader {
  margin-bottom: 16px;
}
.linkedinTitle {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}
.employeeNumber {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.linkedinEmployeeGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.linkedinEmployee {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-width: 0;
  flex: 1;
}

.linkedinEmployee img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #dee3ec;
  flex-shrink: 0;
}

.linkedinEmployee .name {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkedinEmployee .role {
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Footer section */
.viewMoreEmployees {
  display: block;
  width: 50%;
  text-align: center;
  background-color: #f5f7fa;
  padding: 10px 20px;
  font-size: 16px;
  color: #333333;
  text-decoration: none;
  margin-top: 16px;
}

.viewMoreEmployees:hover {
  background-color: #ebedf0;
}